import { defineStore } from 'pinia'
import { ObjectId } from 'bson'
import { DateTime } from 'luxon'
import { datetime, RRule } from 'rrule'
const y2222 = new Date("2222-01-01")
// import { specialTypes } from '../components/calendar/defaultConfig.js'
const arrayToObject = (arr, keyField) => Object.assign({}, ...arr.map(item => ({[item[keyField]]: item})))

const simulateTZ = (dt, zone = Intl.DateTimeFormat().resolvedOptions().timeZone) => { let d = DateTime.fromJSDate(dt).setZone(zone).toObject(); return datetime(d.year, d.month, d.day, d.hour, d.minute, d.second) }
const desimulateTZ = (dt, zone = Intl.DateTimeFormat().resolvedOptions().timeZone) => DateTime.fromJSDate(dt).toUTC().setZone(zone, { keepLocalTime: true }).toJSDate()
const toYMD = (dt, zone = Intl.DateTimeFormat().resolvedOptions().timeZone) => { let d = DateTime.fromJSDate(dt).setZone(zone).toObject(); return `${d.year}-${`${d.month}`.padStart(2, '0')}-${d.day}` }


export const useMainStore = defineStore({
    id: 'mainStore',
    state: () => ({

        online: navigator.onLine, 
        theme: 'os',
        nav: { folded: true }, 
        aside: { folded: true },
        sidebar: { selected: null },
        adminConfig: {},
        help: {},
        teamUser: {_id: new ObjectId("219a4b80bd5deb9e94bd5454"), profile: {fullname: 'Team', firstname: 'Team', lastname: ''}, stitch_id: '219a4b80bd5deb9e94bd5454'},
        supportUser: {_id: new ObjectId("21fd2c000000000000000000"), profile: {fullname: 'Kodoku Support', firstname: 'Kodoku', lastname: 'Support'}, stitch_id: '21fd2c000000000000000000'},
        version: '',
        isEditing: { docx: '', xlsx: '', txt: '', img: '' },
        activeTimingDay: '', 

        modals: [],
        dialogs: [],
        sidebars: [],

        me: null,
        // selectedCustomer: null,
        // selectedTenant: null,
        users: {},
        tenants: [], // nur noch liste der _ids und names!!
        customer: null,
        invites: [],
        // clients: {},
        addressbook: {},
        schedules: [],
        conversations: {},
        announcements: [],
        todos: {},
        todoLists: [],
        overdue: {},
        calendar: {
            officialHolidays: [],
            events: {},
            jobs: {}, //[],
            durations: {}, 
            schedules: [],
            coverage: [], 
            files: [],
            loaders: [],
            loading: false,
            // scheduling: []
            activeTemplateId: '',
            schedulingMode: '',
            schedulingSorting: 'lastname',
            schedulingDisplayMode: 'longterm', 
            schedulingViewScale: 'schedulingMonth',
            flsColumnOrder: 'user-client', //'client-user', // 'user-client',
            flsHiddenPersons: []
        },
        avatarLoadingPool: [
            // 1. if avatar component finds a file without cache url or expired cache url
            // 2. puts _id of avatar here
            // 3. other avatar components don't reload while waiting
        ],
        pusher: {
            client: null,
            socketId: null,
            channels: {
                user: null,
                admin: null,
                customer: null
            }
        }
        // jobs: [],
        // schedulingJobs: []
    }),
    actions: {
        openModal(info) {
            this.modals.push(info)
        },
        openDialog(info) {
            this.dialogs.push(info)
        },
        openSidebar(info) {
            this.sidebars.push(info)
        },
        closeLastSidebar() {
            this.sidebars.pop()
        },
        resetCalendarContext() {
            this.calendar.events = {}
            this.calendar.jobs = {}
            this.calendar.loaders = []
        },
        setTheme(theme, systemDark) {
            console.log(theme, systemDark);
            if(theme) {
                this.theme = theme
                if(theme === 'light') document.documentElement.classList.remove('dark')
                else if(theme === 'dark') document.documentElement.classList.add('dark')
                else if(theme === 'os') {
                    if(window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
                        document.documentElement.classList.add('dark')
                    } else {
                        document.documentElement.classList.remove('dark')
                    }
                }
            } else {
                if(this.theme === 'os') {
                    if(systemDark) {
                        document.documentElement.classList.add('dark')
                        document.querySelector('meta[name="theme-color"]').content = '#1f2937'
                    }
                    else {
                        document.documentElement.classList.remove('dark')
                        document.querySelector('meta[name="theme-color"]').content = '#ffffff'
                    }
                }
            }
        }
    },
    getters: {
        // oddOrEven: (state) => {
        //     if(state.count % 2 === 0) return 'even';
        //     return 'odd';
        // },
        selectedTenant: (state) => {
            return state.tenants.find(t => t._id.equals(state.me.selected.tenant))
        },
        selectedCustomer: (state) => {
            return state.customer
        },

        userContext: (state) => {
            return { me: {_id: state.me._id}, selectedCustomer: {_id: state.selectedCustomer._id, settings: {timezone: state.selectedCustomer.settings.timezone}}, selectedTenant: {_id: state.selectedTenant._id} }
        },
        rolesGranted: (state) => {
            return (roles, context = 'customer') => {
                return state.me ? state.me.rbac?.[context === 'customer' ? state.selectedCustomer._id : state.selectedTenant._id]?.some(r => roles.includes(r)) : false
            }
        },

        calendarReviews: (state) => {
            return (personId = null) => {
                return Object.values(state.calendar.events).filter(
                    ev => (ev.review || ev.review === '') && ev.tenant?.equals(state.selectedTenant._id) && (personId ? ev.attendees?.[0]?.equals(personId) : true)
                )
            }
        },
        openCalendarReviews: (state) => {
            return (personId = null) => state.calendarReviews(personId).filter(r => r.review === '')
        },

        usersById: (state) => {
            return arrayToObject(Object.values(state.users), '_id')
        },
        usersByStitchId: (state) => {
            return arrayToObject(Object.values(state.users), 'stitch_id')
        },
        unreadConversationMessageCount: (state) => {
            // return Object.values(state.conversations).reduce((a,c) => a += c.messages?.filter(
            //     msg => msg._id > c.settings[state.me?.stitch_id].readCursor && msg.author.stitch_id !== state.me?.stitch_id
            // ).length, 0)
            return Object.values(state.conversations).filter(c => !c.archived && !c.deleted && c.members?.map(m => m.toString()).includes(state.me?._id.toString())).reduce(
                (a,c) => a += c.unreadCount, 0
            )
        },
        unreadConversations: (state) => {
            // return Object.values(state.conversations).filter(c => c.messages?.filter(
            //     msg => msg._id > c.settings[state.me?.stitch_id].readCursor && msg.author.stitch_id !== state.me?.stitch_id
            // ).length)
            return Object.values(state.conversations).filter(c => c.unreadCount && !c.archived && !c.deleted)
        },
        myConversations: (state) => {
            return arrayToObject(Object.values(state.conversations).filter(
                conv => conv.members?.map(m => m.toString()).includes(state.me?._id?.toString()) && !conv.archived && !conv.deleted
            ), '_id')
        },
        publicConversations: (state) => {
            return arrayToObject(Object.values(state.conversations).filter(
                conv => !(conv.members?.map(m => m.toString()).includes(state.me?._id?.toString())) && conv.type === 'group' && !conv.private && !conv.archived && !conv.deleted
                    && (!conv.tenant || state.me?.membership?.map(m => m.toString())?.includes(conv.tenant?.toString()))
            ), '_id')
        },
        // activeContacts: (state) => {
        //     return Object.values(state.contacts).filter(c => !c.archived)
        // },
        clientsById: (state) => {
            return arrayToObject(Object.values(state.addressbook).filter(a => a.type === 'client'), '_id')
        },
        clientsByStitchId: (state) => {
            return arrayToObject(Object.values(state.addressbook).filter(a => a.type === 'client' && a.stitch_id), 'stitch_id')
        },
        activeClients: (state) => {
            return Object.values(state.addressbook).filter(c => c.type === 'client' && !c.archived).sort((a,b) => a.profile?.lastname?.localeCompare(b.profile?.lastname))
        },
        activeUsers: (state) => {
            return Object.values(state.users).filter(u => !u.archived && u.active?.map(a => a.toString()).includes(state.selectedCustomer._id.toString())).sort((a,b) => a.profile?.lastname?.localeCompare(b.profile?.lastname))
        },
        inactiveUsers: (state) => {
            return Object.values(state.users).filter(u => !u.archived && !u.active?.map(a => a.toString()).includes(state.selectedCustomer._id.toString())).sort((a,b) => a.profile?.lastname?.localeCompare(b.profile?.lastname))
        },
        // invitedUsers: (state) => {
        //     // return Object.values(state.users).filter(u => !u.archived && u.status === 'pending').sort((a,b) => a.profile?.lastname?.localeCompare(b.profile?.lastname))
        // },

        activeBookmarks: (state) => {
            return [
                ...(state.me?.bookmarks || []).map(b => b.type === 'user' ? state.usersById[b._id] : (b.type === 'addressbook' ? state.addressbook[b._id] : {})),
                /*...state.activeClients.filter(
                    a => !(state.me?.bookmarks||[]).map(b => b._id.toString()).includes(a._id.toString())
                        && (a.employee === state.me?.uid || a.employeeProxy === state.me?.uid)
                )*/
            ].sort((a,b) => a.profile?.lastname?.localeCompare(b.profile?.lastname))
        },

        birthdays: (state) => {
            return [
                ...state.activeUsers.filter(u => u.profile.birthday).map(u => ({ 
                    _id: `${u._id}`, type: 'birthday', allDay: true, title: u.profile.fullname, day: DateTime.fromJSDate(u.profile.birthday).toFormat('MM-dd'),
                    rrules: [{dtstart: new Date(u.profile.birthday), freq: 0, interval: 1}], entity: 'user', group: 'birthdays'
                })),
                ...state.activeClients.filter(c => c.profile.birthday).map(c => ({ 
                    _id: `${c._id}`, type: 'birthday', allDay: true, title: c.profile.fullname, day: DateTime.fromJSDate(c.profile.birthday).toFormat('MM-dd'),
                    rrules: [{dtstart: new Date(c.profile.birthday), freq: 0, interval: 1}], entity: 'client', group: 'birthdays'
                }))
            ]
        },

        officialHolidays: (state) => {
            return Object.values(state.calendar.events).filter(ev => ev.type === 'official-holiday').filter(
                h => h.feds?.includes('NATIONAL') || state.selectedTenant?.settings?.holidays?.[h._id]?.conditions?.filter(
                    con => con.effective <= DateTime.local().startOf('year').toJSDate()
                )?.slice(0)?.sort((a,b) => b.effective - a.effective)?.[0]?.active
            )
        },


        clientsInCare: (state) => { // return Object.values(state.addressbook).filter(a => a.employee == state.me?.uid)
            return [...new Set([
                ...state.schedules.filter(
                    s => (s.care?.filter(c => c.effective < Date.now()).slice(0).sort((a,b) => b.effective - a.effective)?.[0]?.user?.toString() === state.me?._id.toString()) 
                        && (s.end || y2222) > Date.now() && !state.addressbook[s.client]?.archived && !state.addressbook[s.client]?.deleted
                ).map(s => s.client.toString()),
                ...Object.values(state.addressbook).filter(a => a.employee == state.me?.uid && !a.archived && !a.deleted).map(a => a._id.toString())
            ])].map(id => state.addressbook[id]).filter(a => a)
        },
        clientsProxy: (state) => { // return Object.values(state.addressbook).filter(a => a.employeeProxy == state.me?.uid)
            return [...new Set([
                ...state.schedules.filter(
                    s => (s.proxy?.filter(p => p.effective < Date.now()).slice(0).sort((a,b) => b.effective - a.effective)?.[0]?.user?.toString() === state.me?._id.toString()) 
                        && (s.end || y2222) > Date.now() && !state.addressbook[s.client]?.archived && !state.addressbook[s.client]?.deleted
                ).map(s => s.client.toString()),
                ...Object.values(state.addressbook).filter(a => a.employeeProxy == state.me?.uid && !a.archived && !a.deleted).map(a => a._id.toString())
            ])].map(id => state.addressbook[id]).filter(a => a)
        },
        clientsCareAndProxy: (state) => {
            // return [...state.clientsInCare, ...state.clientsProxy]
            return [...new Map([...state.clientsInCare, ...state.clientsProxy].map(item => [item['_id'], item])).values()]
        },



        todosForMe: (state) => {
            return Object.values(state.todos).filter(td => td.tenant?.equals(state.selectedTenant._id) && !td.deleted && !td.archived && !td.parent && td.responsible?.map(r => r.toString()).includes(state.me._id?.toString()))
        },
        todosForTeam: (state) => {
            return Object.values(state.todos).filter(td => td.tenant?.equals(state.selectedTenant._id) && !td.deleted && !td.archived && !td.parent && td.responsible?.map(r => r.toString()).includes(state.teamUser._id.toString()))
        },
        todosForCare: (state) => {
            return Object.values(state.todos).filter(td => td.tenant?.equals(state.selectedTenant._id) && !td.deleted && !td.archived && !td.parent && td.responsible?.map(r => r.toString()).some(r => state.clientsInCare.map(c => c._id.toString()).includes(r)))
        },
        todosForList: (state) => {
            return (listId) => { return Object.values(state.todos).filter(td => td.tenant?.equals(state.selectedTenant._id) && !td.archived && !td.deleted && !td.parent && td.list?.equals(listId)) }
        },
        todosOverdue: (state) => {
            return [...state.todosForMe, ...state.todosForTeam, ...state.todosForCare].filter(td => !td.deleted && !td.archived && !td.completed && td.due && td.due < DateTime.local().startOf('day').toJSDate())
        },
        todosDone: (state) => {
            return [...state.todosForMe, ...state.todosForTeam, ...state.todosForCare].filter(td => !td.deleted && !td.archived && td.completed && td.completed < Date.now()).slice(0).sort((a,b) => b.completed - a.completed)
        },

        todosForDay: (state) => {
            return (day, people) => {
                return Object.values(state.todos).filter(
                    todo => !people.length || todo.responsible?.map(r => `${r}`).filter(value => people.includes(value)).length
                ).filter(
                    item => {
                        let rrule = item.rrules ? (({ duration, label, ...o }) => o)((item.rrules.find(r => r.dtstart <= day && r.until >= DateTime.fromJSDate(day).plus({days: 1}).toJSDate()) || item.rrules.slice(0).pop())) : null
                        //if(item.allDay && rrule) {
                        return !item.deleted && !item.archived && item.tenant?.equals(state.selectedTenant._id) && (
                        !item.completed 
                        || (item.completed && DateTime.fromJSDate(item.completed).toFormat('yyyy-MM-dd') === DateTime.fromJSDate(day).toFormat('yyyy-MM-dd'))
                        || item.ticks?.map(t => t.day).includes(DateTime.fromJSDate(day).toFormat('yyyy-MM-dd'))
                    ) && (item.rrules ? /* (new RRule(
                        rrule
                    )).between(DateTime.fromJSDate(day).startOf('day').toJSDate(), DateTime.fromJSDate(day).endOf('day').toJSDate(), true).length > 0 */
                    (new RRule(
                        { ...rrule, dtstart: simulateTZ(rrule.dtstart) }
                    )).between(
                        simulateTZ(DateTime.fromJSDate(day).startOf('day').toJSDate()),         // TODO:  analog überall für todos und AUCH für events einbauen!!!
                        simulateTZ(DateTime.fromJSDate(day).endOf('day').toJSDate()), 
                        true
                    ).length > 0
                    : (item.due ? (DateTime.fromJSDate(item.due).toFormat('yyyy-MM-dd') === DateTime.fromJSDate(day).toFormat('yyyy-MM-dd')) : false)
                    )
                })
            }
        },

        // eventsForDay: (state) => {
        //     return (day, personId) => {
        //         return [
        //             ...[...state.activeUsers, ...state.activeClients].filter(person => person.profile?.birthday && day.getMonth() == person.profile.birthday.getMonth() && day.getDate() == person.profile.birthday.getDate()).map(person => ({
        //                 rrules: [{freq: 0, dtstart: person.profile?.birthday}], title: person.profile?.fullname, group: 'birthdays', type: 'birthday', birthday: `${person._id}`, allDay: true, refTime: DateTime.fromISO(dayStr).toJSDate(),
        //             })).filter(item => !personId || personId.toString() === item.birthday.toString()),

        //             ...Object.values(state.calendar.events).filter(ev => !/shift/i.test(ev.type) && true).filter(
        //                 item => {
        //                     let rrule = item.rrules ? (({ duration, label, ...o }) => o)((item.rrules.find(r => r.dtstart <= day && r.until >= DateTime.fromJSDate(day).plus({days: 1}).toJSDate()) || item.rrules.slice(0).pop())) : null
        //                     return item.rrules ? (new RRule({ ...rrule, dtstart: simulateTZ(rrule.dtstart)})).between(
        //                         simulateTZ(DateTime.fromJSDate(day).startOf('day').toJSDate()), 
        //                         simulateTZ(DateTime.fromJSDate(day).endOf('day').toJSDate()), 
        //                         true
        //                     ).length > 0
        //                 : item.days?.includes(DateTime.fromJSDate(day).toFormat('yyyy-MM-dd'))
        //             })
        //         ]
        //     }
        // },

        overdueItems: (state) => {
            return [
                {group: 'overdue', type: 'fls', items: state.overdue.fls?.filter(fls => fls.attendees?.map(r => r.toString()).includes(state.me._id.toString())) || []},
                {group: 'overdue', type: 'shift', items: state.overdue.shifts?.filter(
                    shift => shift.attendees?.map(r => r.toString()).includes(state.me._id.toString())
                ) || []},
                {group: 'overdue', type: 'todo', items: state.overdue.todos?.filter(todo => todo.responsible?.map(r => r.toString()).includes(state.me._id.toString())) || []},
            ]
        },

        overdueShifts: (state) => {
            return state.overdue.shifts?.filter(
                shift => shift.attendees?.map(r => r.toString()).includes(state.me._id.toString()) && shift.status === 'published' && !shift.fixed
            ) || []
        },


        dayPlanFiltered: (state) => {
            return (filters) => {
                let day = filters.day 
                let people = (filters.people || []).map(p => `${p._id}`)
                let dayStr = DateTime.fromJSDate(day).toFormat('yyyy-MM-dd')
                let dayEnd = DateTime.fromJSDate(day).plus({days: 1}).toJSDate()
                let myClientIds = state.clientsInCare.map(c => c._id)
                return [

                    ...state.birthdays.filter(item => !people.length || people.includes(item._id.toString())).filter(
                        b => (new RegExp(`${b.day}$`)).test(dayStr)
                    ).map(b => ({ ...b, refTime: DateTime.fromISO(dayStr).toJSDate() })),
                    
                    ...state.todosForDay(day, people),

                    ...Object.values(state.calendar.events).filter(ev => !/shift/i.test(ev.type)).filter(
                        item => (
                            // people.length ? (item.attendees?.map(r => r.toString()).includes(personId.toString()) || item.beneficiaries?.map(r => r.toString()).includes(personId.toString() || item.type === 'official-holiday')
                            people.length ? (item.attendees?.map(r => `${r}`).filter(value => people.includes(value)).length || item.beneficiaries?.map(r => `${r}`).filter(value => people.includes(value)).length || item.type === 'official-holiday'
                            ) : (
                                [state.me._id, state.teamUser._id, ...myClientIds].map(id => id.toString()).some(id => (
                                    item.attendees?.map(r => r.toString()).includes(id) || item.beneficiaries?.map(r => r.toString()).includes(id)
                                )) || item.type === 'official-holiday'
                            )
                        ) && !item.deleted && item.status !== 'draft'
                            && ((item.tenant?.toString() === state.selectedTenant?._id?.toString()) || ['vacation', 'holiday', 'sick-leave', 'official-holiday'].includes(item.type))
                            // TEST FOR WRONG GROUP
                    ).filter(item => {
                        
                        let rrule = item.rrules ? (({ duration, label, ...o }) => o)((item.rrules.find(r => r.dtstart <= day && r.until >= DateTime.fromJSDate(day).plus({days: 1}).toJSDate()) || item.rrules.slice(0).pop())) : null
                        // if(rrule) {
                        //     let tzOffset = (new Date()).getTimezoneOffset()
                        //     rrule.dtstart = DateTime.fromISO(rrule.dtstart.toISOString()).plus({ minutes: tzOffset }).toJSDate()
                        // }

                        return item.rrules ? (new RRule(
                            { ...rrule, dtstart: rrule.dtstart ? simulateTZ(rrule.dtstart) : new Date("2000-01-01") }
                        )).between(
                            simulateTZ(DateTime.fromJSDate(day).startOf('day').toJSDate()), 
                            simulateTZ(DateTime.fromJSDate(day).endOf('day').toJSDate()), 
                            true
                        ).length > 0
                        : item.days?.includes(DateTime.fromJSDate(day).toFormat('yyyy-MM-dd'))
                    }
                    ).map(
                        item => ({...item, refTime: DateTime.fromISO(`${dayStr}T${DateTime.fromJSDate(item.start).toFormat('HH:mm')}:00`).toJSDate()})
                    ),

                    ...(people.length ? [] : Object.entries(Object.values(state.calendar.events).filter(
                        ev => /shift/i.test(ev.type) && ev.status !== 'draft' && ev.days?.includes(dayStr)
                            && ((ev.tenant?.toString() === state.selectedTenant?._id?.toString()) || ['vacation', 'holiday', 'sick-leave'].includes(ev.type))
                            // TEST FOR WRONG GROUP
                    ).reduce(
                        (a,ev,i,arr) => a = {...a, [`${ev.type}::${DateTime.fromJSDate(new Date(Math.max(...[ev.start, day]))).toFormat('HH:mm')}`]: arr.filter(
                            e => e.type === ev.type && e.start.getTime() === ev.start.getTime()
                        )}
                    , {}))).reduce(
                        (a,[key, shifts], i) => a = [...a, {
                            type: key.split('::')[0], 
                            refTime: DateTime.fromISO(`${dayStr}T${key.split('::')[1]}:00`).toJSDate(),
                            shifts: shifts.map(ev => ({
                                _id: ev._id, 
                                code: ev.code, //user: ev.attendees[0]
                                user: ev.attendees[0],
                                fullname: state.usersById[ev.attendees[0]]?.profile?.fullname || '',
                                lastname: state.usersById[ev.attendees[0]]?.profile?.lastname || ''
                            })).filter(ev => ev.lastname).slice(0).sort((a,b) => a.lastname.localeCompare(b.lastname)),
                        }], []
                    )

                ].filter(
                    item => true // (personId?.toString() === state.me?._id?.toString() || !personId) ? true : false
                )
                /*.filter(
                    item => (item.group === 'overdue' && item.items?.length > 0) || 
                    ((item.rrules ? (new RRule(
                        (({ duration, label, ...o }) => o)((item.rrules.find(r => r.dtstart <= day && r.until >= DateTime.fromJSDate(day).plus({days: 1}).toJSDate()) || item.rrules.slice(0).pop()))
                    )).between(
                        DateTime.fromJSDate(day).startOf('day').toJSDate(), DateTime.fromJSDate(day).endOf('day').toJSDate(), true
                    ).length > 0
                    : (item.days?.includes(DateTime.fromJSDate(day).toFormat('yyyy-MM-dd'))
                    || (item.start < DateTime.fromJSDate(day).plus({days: 1}).toJSDate() 
                        && ((item.completed && item.completed > day && item.completed < DateTime.fromJSDate(day).plus({days: 1}).toJSDate())
                        || (!item.completed && item.end && item.end > day) || (!item.completed && item.end == null && !item.birthday))))
                    ))
                )*/
            }
        },


        dayPlanGrouped: (state) => {
            return (filters) => { console.log(filters);

                // shifts, fls, flextime, sick-leave, holiday, vacation, scheduling-wish, blocker, 
                // official-holiday, group, todos
                
                let day = filters.day || DateTime.local().startOf('day').toJSDate();
                let isPersonMe = filters.person?.uid && filters.person?.uid == state.me.uid
                let birthdays = (filters.person?.type === 'client') ? [filters.person] : [...state.activeUsers, ...state.activeClients]
                let people = (filters.person?.type === 'client') ? [filters.person] : isPersonMe ? [state.me, state.teamUser] : [...state.activeClients, ...state.activeUsers]
                return [
                    ...birthdays.filter(person => person.profile?.birthday).map(
                        person => ({rrules: [{freq: 0, dtstart: person.profile?.birthday}], title: person.profile?.fullname, group: 'birthdays', type: 'birthday', birthday: `${person._id}`, allDay: true})
                    ),
                    ...(isPersonMe ? [
                        {group: 'overdue', type: 'shift', items: state.overdue.shifts || []},
                        {group: 'overdue', type: 'fls', items: state.overdue.fls || []},
                        {group: 'overdue', type: 'todo', items: state.overdue.todos || []},
                    ] : []),
                    ...people.reduce(
                        (a,p) => a = [...a, ...[...Object.values(state.todos), ...Object.values(state.calendar.events)].filter(
                            item => (
                                !a.map(aa => aa._id?.toString()).includes(item._id?.toString()) && 
                                (item.responsible || item.watchers || item.attendees)?.map(r => r.toString()).includes(p._id.toString())
                                || item.beneficiaries?.map(r => r.toString()).includes(p._id.toString()) 
                            ) && !item.deleted && item.status !== 'draft' && (isPersonMe ? true : true)//['shift', 'holiday', 'vacation', 'sick-leave'].some(k => item.type?.includes(k)))
                        )], []
                    ),
                ].filter(
                    item => (item.group === 'overdue' && item.items?.length > 0) || 
                    ((item.rrules ? (new RRule(
                        (({ duration, label, ...o }) => o)((item.rrules.find(r => r.dtstart <= day && r.until >= DateTime.fromJSDate(day).plus({days: 1}).toJSDate()) || item.rrules.slice(0).pop()))
                    )).between(
                        DateTime.fromJSDate(day).startOf('day').toJSDate(), DateTime.fromJSDate(day).endOf('day').toJSDate(), true
                    ).length > 0
                    : (item.days?.includes(DateTime.fromJSDate(day).toFormat('yyyy-MM-dd'))
                    || (item.start < DateTime.fromJSDate(day).plus({days: 1}).toJSDate() 
                        && ((item.completed && item.completed > day && item.completed < DateTime.fromJSDate(day).plus({days: 1}).toJSDate())
                        || (!item.completed && item.end && item.end > day) || (!item.completed && item.end == null && !item.birthday))))
                    ))
                )

                return [
                    ...[...state.activeUsers, ...state.activeClients].filter(person => person.profile?.birthday).map(
                        person => ({rrules: [{freq: 0, dtstart: person.profile?.birthday}], title: person.profile?.fullname, group: 'birthdays', birthday: person._id.toString()})
                    ),
                    ...(filters.mode === 'me' ? [
                        {group: 'overdue', type: 'shift', items: state.overdue.shifts || []},
                        {group: 'overdue', type: 'fls', items: state.overdue.fls || []},
                        {group: 'overdue', type: 'todo', items: state.overdue.todos || []},
                    ] : []),
                        ...(
                            (
                                filters.mode === 'me' ? [state.me._id.toString(), state.teamUser._id.toString()] 
                                : [...state.activeClients.map(c => c._id), ...state.activeUsers.map(u => u._id)]
                            ).reduce(
                        (a,meId) => a = [...a, ...[...Object.values(state.todos), ...Object.values(state.calendar.events)].filter(
                            item => (
                                (item.responsible || item.watchers || item.attendees)?.map(r => r.toString()).includes(meId.toString())
                                || item.beneficiaries?.map(r => r.toString()).includes(meId.toString()) 
                            ) && !item.deleted && item.status !== 'draft' && (
                                filters.mode === 'me' ? true : ['shift', 'holiday', 'vacation', 'sick-leave'].some(k => item.type?.includes(k))
                            )
                        )], []
                    )),
                ].filter(
                    item => (item.group === 'overdue' && item.items?.length > 0) || 
                    ((item.rrules ? (new RRule(
                        (({ duration, label, ...o }) => o)((item.rrules.find(r => r.dtstart <= day && r.until >= DateTime.fromJSDate(day).plus({days: 1}).toJSDate()) || item.rrules.slice(0).pop()))
                    )).between(
                        DateTime.fromJSDate(day).startOf('day').toJSDate(), DateTime.fromJSDate(day).endOf('day').toJSDate(), true
                    ).length > 0
                    : (item.days?.includes(DateTime.fromJSDate(day).toFormat('yyyy-MM-dd'))
                    || (item.start < DateTime.fromJSDate(day).plus({days: 1}).toJSDate() 
                        && ((item.completed && item.completed > day && item.completed < DateTime.fromJSDate(day).plus({days: 1}).toJSDate())
                        || (!item.completed && item.end && item.end > day) || (!item.completed && item.end == null && !item.birthday))))
                    ))
                )

                return [state.teamUser._id.toString(), state.me._id?.toString() || '', ...state.activeUsers.map(u => u._id), ...state.activeClients.map(c => c._id)].reduce(
                    (a,_id) => a = {...a, [_id]: [
                        ...(!filters.categories?.length || filters.categories?.includes('todos') ? Object.values(state.todos) : []), 
                        ...(!filters.categories?.length || ['fls', 'group', 'shift'].some(k => filters.categories?.includes(k)) ? Object.values(state.calendar.events).filter(
                            ev => !filters.categories?.length || filters.categories?.some(c => ev.type?.includes(c))
                        ) : []),
                        ...(state.addressbook[_id] && state.addressbook[_id].profile?.birthday ? [
                            ...(!filters.categories?.length || filters.categories?.includes('birthdays') ? [
                                {rrules: [{freq: 0, dtstart: state.addressbook[_id].profile?.birthday}], group: 'birthdays', birthday: _id.toString()}
                            ] : [])
                        ] : []),
                        ...(state.usersById[_id] && state.usersById[_id].profile?.birthday ? [
                            ...(!filters.categories?.length || filters.categories?.includes('birthdays') ? [
                                {rrules: [{freq: 0, dtstart: state.usersById[_id].profile?.birthday}], group: 'birthdays', birthday: _id.toString()}
                            ] : [])
                        ] : []),
                        {group: 'overdue', type: 'fls', items: state.overdue.fls?.filter(
                            fls => _id.toString() === state.me?._id.toString() && fls.attendees?.map(r => r.toString()).includes(_id.toString())
                            // (_id.toString() === state.me?._id.toString() || state.rolesGranted(['admin', 'pedagogic']))
                        )},
                        {group: 'overdue', type: 'shift', items: state.overdue.shifts?.filter(
                            shift => _id.toString() === state.me?._id.toString() && shift.attendees?.map(r => r.toString()).includes(_id.toString())
                        )},
                        {group: 'overdue', type: 'todo', items: state.overdue.todos?.filter(
                            todo => _id.toString() === state.me?._id.toString() && todo.responsible?.map(r => r.toString()).includes(_id.toString())
                        )},
                    ].filter( 
                        item => (item.group === 'overdue' && item.items?.length > 0) || 
                        ((
                            (item.responsible || item.watchers || item.attendees)?.map(r => r.toString()).includes(_id.toString()) 
                            || item.beneficiaries?.map(r => r.toString()).includes(_id.toString()) 
                            || item.birthday
                        ) && !(item.deleted)
                        && item.status !== 'draft' 
                        && (
                            item.rrules ? (new RRule(
                                (({ duration, label, ...o }) => o)((item.rrules.find(r => r.dtstart <= day && r.until >= DateTime.fromJSDate(day).plus({days: 1}).toJSDate()) || item.rrules.slice(0).pop()))
                            )).between(
                                DateTime.fromJSDate(day).startOf('day').toJSDate(), DateTime.fromJSDate(day).endOf('day').toJSDate(), true
                            ).length > 0
                            : (item.days?.includes(DateTime.fromJSDate(day).toFormat('yyyy-MM-dd'))
                                || (item.start < DateTime.fromJSDate(day).plus({days: 1}).toJSDate() 
                                    && ((item.completed && item.completed > day && item.completed < DateTime.fromJSDate(day).plus({days: 1}).toJSDate())
                                    || (!item.completed && item.end && item.end > day) || (!item.completed && item.end == null && !item.birthday)))
                            )
                        ))
                    )}, {}
                )
            }
        },

        activeSchedulingTemplate: (state) => {
            return state.selectedTenant?.settings?.dutyTemplates?.find(dt => dt?._id?.toString() === state.calendar.activeTemplateId.toString())
                // || specialTypes.find(s => s._id?.toString() === state.calendar.activeTemplateId.toString())
        },
    }
})